import Vue from "vue";

import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import enBlocks from "./locales/en/blocks";
import enNavigation from "./locales/en/navigation";
import enMe from "./locales/en/me";
import enLogin from "./locales/en/login";
import enClaims from "./locales/en/claims";
import enLegal from "./locales/en/legal";

const localStorageLang = localStorage.getItem("locale");

export const i18n = new VueI18n({
  locale: localStorageLang || "en",
  fallbackLocale: "en",
  messages: {
    en: {
      blocks: enBlocks,
      navigation: enNavigation,
      me: enMe,
      login: enLogin,
      claims: enClaims,
      legal: enLegal,
    },
  },
});
