import Vue from "vue";
import { Action, Rejection, View, Completion, Referral, FirestoreInstance } from "@claimsgate/core-types";
import { ClaimDataService } from "../ClaimsGate/DataService";
import { getUserHelper } from "../ClaimsGate/UserHelper";
import {
  ClaimsGateVariables,
  getClaimRefByUserId,
  getFunnel,
  getLatestClaim,
  WorkspaceService,
} from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { WorkspaceReferralService } from "../ClaimsGate/referral/WorkspaceReferralService";
import { calculateTermsOfBusinessRate } from "./thompsons";
import { buildFunnelParamSlug } from "@/helpers/ClaimsGate/utils/buildFunnelParamSlug";
import { onCallGateway } from "../ClaimsGate/onCallGateway";

/**
 * Describes the common state keys that are used in the helper functions
 */
interface BaseFormState {
  claimId: string;
  userId: string;
  pageId?: string;
  funnelId: string;
  claimDataService: ClaimDataService;
  funnelSlug?: string;
}

/** Creates a claim data service and a claim identifier if it does not already exist */
export function createClaimDataService<T extends BaseFormState & Vue>(state: T) {
  const userService = getUserHelper();
  if (!state.userId) {
    state.userId = userService.getUserId();
  }
  if (!state.claimId) {
    state.claimId = userService.createClaimId();

    state.claimDataService = new ClaimDataService(state.userId, state.claimId);
    state.claimDataService.setArtefact("claimId", state.claimId);

    // Now we need to emit a message to Form.vue which will update its claimId
    state.$store.dispatch("events/fire", {
      name: "updateClaimId",
      claimId: state.claimId,
    });
  }

  if (!state.claimDataService) {
    state.claimDataService = new ClaimDataService(state.userId, state.claimId);
    state.claimDataService.setArtefact("claimId", state.claimId);
  }
}

/** Returns the claim data service */
export async function getClaimDataService<
  T extends { claimId: string; userId: string; claimDataService: ClaimDataService } & Vue
>(state: T) {
  state.claimDataService = new ClaimDataService(state.userId, state.claimId);
}

export function emitSubmit<T extends Vue>(state: T): void {
  state.$store.dispatch("events/fire", { name: "next" });
}

/** Logs a rejection on the claim */
export function reject<T extends BaseFormState & Vue>(state: T, error: string) {
  // Check if the claim already has a list of actions, if it doesn't then create it
  const actions: Array<Action> = state.claimDataService.getArtefact("actions") ?? [];

  // Check if the claim alreday has a completion action for this page, if it does then return
  if (actions?.filter((action) => action.kind === "completion" && action.pageId === state.pageId).length > 0) {
    return;
  }

  // Create the Rejection action
  const rejection: Rejection = {
    kind: "rejection",
    error,
    pageId: state.pageId,
    funnelId: state.funnelId,
    date: new Date(),
  };

  // Add the rejection to the list of actions
  actions.push(rejection);

  // Save the actions to the claim data service
  state.claimDataService.setArtefact("actions", actions);

  // Update the actions artefact on the claim
  getClaimRefByUserId(getFirebaseBackend().firestore(), state.userId, state.claimId).then(([claimRef]) => {
    claimRef.update({ actions: actions });
  });
}

/** Logs a view on the claim */
export function view<T extends BaseFormState & Vue>(state: T, forceStore = false) {
  // Check if the claim already has a list of actions, if it doesn't then create it
  const actions: Array<Action> = state.claimDataService.getArtefact("actions") ?? [];

  const hasCompletion =
    actions?.filter((action) => action.kind === "completion" && action.pageId === state.pageId).length > 0;
  // Check if the claim alreayd has a completion action for this page, if it does then return
  if (hasCompletion && !forceStore) {
    return;
  }

  // Create the View action
  const view: View = {
    kind: "view",
    pageId: state.pageId,
    funnelId: state.funnelId,
    date: new Date(),
  };

  // Add the view to the list of actions
  actions.push(view);

  // Save the actions to the claim data service
  state.claimDataService.setArtefact("actions", actions);

  // Update the actions artefact on the claim
  getClaimRefByUserId(getFirebaseBackend().firestore(), state.userId, state.claimId).then(([claimRef]) => {
    if (claimRef) claimRef.update({ actions: actions });
  });
}

/** Logs a completion on the claim */
export function complete<T extends BaseFormState & Vue>(state: T) {
  // Check if the claim already has a list of actions, if it doesn't then create it
  const actions: Array<Action> = state.claimDataService.getArtefact("actions") ?? [];

  const existingActionIndex = actions?.findIndex(
    (action) => action.kind === "completion" && action.pageId === state.pageId
  );
  // Check if the claim already has a completion action for this page, if so remove it, as this is likey a back button usage
  if (existingActionIndex > -1) {
    actions.splice(existingActionIndex, 1);
  }

  // Create the View action
  const completion: Completion = {
    kind: "completion",
    pageId: state.pageId,
    funnelId: state.funnelId,
    date: new Date(),
  };

  // Add the completion to the list of actions
  actions.push(completion);

  // Save the actions to the claim data service
  state.claimDataService.setArtefact("actions", actions);
}

/** Creates a claim in Firestore */
export async function createClaim<T extends BaseFormState & Vue>(state: T) {
  const db = getFirebaseBackend().firestore();
  const [funnelMeta, _getFunnelError] = await getFunnel(db, state.funnelId);

  const authorId = funnelMeta.authorId;

  const workspaceReferralService = new WorkspaceReferralService();

  // Capture Facebook browser pixel cookie with fault tolerance
  let fbpCookie = "";
  let fbcCookie = "";

  try {
    // Check if document exists (for server-side rendering scenarios)
    if (typeof document !== "undefined" && document?.cookie) {
      // Safely extract the fbp cookie if it exists
      fbpCookie =
        document.cookie
          .split("; ")
          .find((row) => row?.startsWith("_fbp="))
          ?.split("=")[1] || "";

      // Similarly extract the fbc cookie if it exists
      fbcCookie =
        document.cookie
          .split("; ")
          .find((row) => row?.startsWith("_fbc="))
          ?.split("=")[1] || "";
    }
  } catch (error) {
    console.warn("Failed to retrieve Facebook cookies:", error);
    // Continue execution - just won't have the values
  }

  const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());

  // ! At this point, we need to add the workspace, who is the author to the user and claim data services
  // ! list of workspaces who can access their claim data and workspace data
  const workspacesWithTrackingDataAccess = [funnelMeta.authorId];
  const workspacesWithClaimDataAccess = [funnelMeta.authorId];

  // If there is an originator in local storage then add the workspaceId of the originator to the
  // tracking data array
  const referral: Referral | Record<string, string> =
    workspaceReferralService.getReferralFromLocalStorage(localStorage) ?? {};

  const claimStatus = calculateClaimStatus(state);
  console.log("[CLAIMSTATUS] Claim status is", claimStatus);

  const store = {
    actions: state.claimDataService.getArtefact("actions"),
    currentFunnelId: state.funnelId,
    currentPageId: state.pageId,
    [ClaimsGateVariables.Tracking.utmMedium.id]: referral.utmMedium ?? "",
    [ClaimsGateVariables.Tracking.utmSource.id]: referral.utmSource ?? "",
    [ClaimsGateVariables.Tracking.utmCampaign.id]: referral.utmCampaign ?? "",
    [ClaimsGateVariables.Tracking.referUrl.id]: referral.refer ?? "",
    adId: referral.adId ?? "",
    claimStatus,
    clientClaimProgress: "inProgress",
    workspacesWithClaimDataAccess: [],
    workspacesWithTrackingDataAccess: [],
    clientUserAgent: navigator.userAgent,
    fbp: fbpCookie || "",
    fbc: fbcCookie || "",
  };

  // Check if the funnel is xIIzib4WMmdDg6Sa8R8B

  const milbergFunnelId = "xIIzib4WMmdDg6Sa8R8B";
  if (state.funnelId === milbergFunnelId) {
    // Generate a random number between 0 and 1 and round it to the nearest integer
    const randomNumber = Math.round(Math.random());

    // If 0, set store.splitTest to 'A'
    // If 1, set store.splitTest to 'B'
    store.splitTest = randomNumber === 0 ? "A" : "B";
  }

  // If there is an originator for this claimant and the funnel has authorised
  // this originate to refer clients.
  if (referral?.workspaceReferrerId) {
    // Validate if the workspace referral identifier is a valid workspace
    const { data: referrerWorkspace } = await workspaceService.getWorkspace(referral.workspaceReferrerId);

    // Validate the referrer is not the funnel author to prevent duplicate in the array
    if (referrerWorkspace) {
      if (referral.workspaceReferrerId !== authorId) {
        workspacesWithTrackingDataAccess.push(referral.workspaceReferrerId);
      }
      // ? This is correct, we are adding a non-hashed variable
      // ? to the hashed store don't worry!

      store.referrerId = referral.workspaceReferrerId;
    }
  }

  store.workspacesWithClaimDataAccess = workspacesWithClaimDataAccess;
  store.workspacesWithTrackingDataAccess = workspacesWithTrackingDataAccess;

  // If there is no referral
  if (Object.keys(referral).length === 0 || !referral.workspaceReferrerId) {
    // Find the latest claim the client created on this funnel
    const [latestClaim, _latestClaimError] = await getLatestClaim(db, state.userId, state.funnelId);

    // If there is a latest claim
    if (latestClaim && latestClaim.referrerId) {
      // Then copy the utmSource, utmMedium, utmCampaign, referUrl, adId, workspacesWithTrackingDataAccess and referrerI
      store.referrerId = latestClaim.referrerId;
      store.workspacesWithTrackingDataAccess = latestClaim.workspacesWithTrackingDataAccess;
      store[ClaimsGateVariables.Tracking.utmMedium.id] = latestClaim[ClaimsGateVariables.Tracking.utmMedium.id] ?? "";

      // We will only use the latest claims utmSource if it is not created by the API
      if (latestClaim[ClaimsGateVariables.Tracking.utmSource.id] !== "api") {
        store[ClaimsGateVariables.Tracking.utmSource.id] = latestClaim[ClaimsGateVariables.Tracking.utmSource.id] ?? "";
      }

      store[ClaimsGateVariables.Tracking.utmCampaign.id] =
        latestClaim[ClaimsGateVariables.Tracking.utmCampaign.id] ?? "";
      store[ClaimsGateVariables.Tracking.referUrl.id] = latestClaim[ClaimsGateVariables.Tracking.referUrl.id] ?? "";
      store.adId = latestClaim.adId ?? "";
    }
  }

  // Clear the referrer from local storage
  workspaceReferralService.clearReferrer(localStorage);

  await createClaimDocument(db, store, state.claimId);
  await state.claimDataService.refresh();

  getClientIpAddress().then((clientIpAddress) => {
    state.claimDataService.setArtefact("clientIpAddress", clientIpAddress);
  });

  calculateTermsOfBusinessRate(state.claimDataService, state.funnelId, state.$route.query);

  // ! We may want to call update on userDataService and claimDataService here

  // Update url parameters
  // If the page was originally accessed with a slug, we want to keep it in the path param for the funnelId
  const funnelId = buildFunnelParamSlug(state.funnelId, state.funnelSlug);

  state.$router.push({
    name: "form",
    params: { funnelId: funnelId, claimId: state.claimId, pageId: state.pageId },
    query: { ...state.$route.query },
  });
}

/**
 * Make a request to get the users IP address
 */
export async function getClientIpAddress() {
  const response = await onCallGateway<"getUserIPAddress">({ functionName: "getUserIPAddress" });

  return response?.data;
}

/** Creates the document for a claim in Firestore */
async function createClaimDocument(
  db: FirestoreInstance,
  store: Record<string, any>,
  claimId?: string
): Promise<string> {
  const userRef = db.collection("users").doc(getUserHelper().getUserId());

  let claimRef = userRef.collection("claims").doc();
  if (claimId) {
    claimRef = userRef.collection("claims").doc(claimId);
  }

  console.log("Store is:", store);
  await claimRef.set(
    {
      ...store,
      lastUpdated: new Date(),
      createdAt: new Date(),
      updatedAt: new Date(),
      documentId: claimRef.id,
      claimId: claimRef.id,
      userId: getUserHelper().getUserId(),
    },
    { merge: true }
  );

  return claimId;
}

/**
 * Check if the testClaim query param has been passed in. Also check the current user is part of the CG team. If so set claim to testing.
 */
function calculateClaimStatus<T extends BaseFormState & Vue>(state: T) {
  /**
   * CG Team Emails
   */
  const testingEmails = [
    // Rayyam
    "rayyandev@gmail.com",
    // Max
    "max.a.raju@googlemail.com",
    "max.a.raju@gmail.com",
  ];

  const currentUserEmail = getFirebaseBackend().getAuthenticatedUser()?.email;
  console.log("[CLAIMSTATUS] Current user email is", currentUserEmail);
  const claimStatus = state.$route.query.testClaim === null ? "testing" : "active";
  if (!currentUserEmail) return claimStatus;

  if (
    testingEmails.includes(currentUserEmail) ||
    currentUserEmail.includes("claimsgate.co.uk") ||
    currentUserEmail.includes("mediaadvancements.com")
  ) {
    return "testing";
  }

  return claimStatus;
}
