import { ClaimsGateFunctionReturnedError } from "@claimsgate/core";
import { VariableType } from "@claimsgate/core-types";
import { onCallCrmGateway } from "@/helpers/ClaimsGate/onCallGateway";

/** @return Returns a boolean indicating whether or not the provided variable type can be edited */
export function isEditableVariableType(type: VariableType): boolean {
  const EDITABLE_VARIABLE_TYPES: Array<VariableType> = [
    "boolean",
    "date",
    "number",
    "file",
    "array of files",
    "string",
    "T",
    "Array<T>",
  ];

  return EDITABLE_VARIABLE_TYPES.includes(type);
}

/** Type guard to check if a returned function response is a Claims Gate function returned error */
export function isClaimsGateFunctionReturnedError<T>(response: {
  data?: ClaimsGateFunctionReturnedError | T;
}): response is { data: ClaimsGateFunctionReturnedError } {
  return (
    typeof response.data === "object" &&
    !Array.isArray(response) &&
    (response.data as ClaimsGateFunctionReturnedError).error !== undefined
  );
}

/** Type guard to check if a given value is not a ClaimsgateFunctionReturnedError */
export function isNotClaimsGateFunctionReturnedError<T>(response: {
  data: ClaimsGateFunctionReturnedError | T;
}): response is { data: T } {
  return !isClaimsGateFunctionReturnedError(response);
}

export function preprocessFilters(filters: any[] | any[][]) {
  if (Array.isArray(filters) && filters.length > 0 && filters.every((filter) => Array.isArray(filter))) {
    return filters.map((filterGroup) => preprocessFilters(filterGroup));
  }

  return filters.map((filter) => {
    const _filter: any = {
      variable: filter.variable.id,
      type: filter.variable.type,
      condition: filter.condition,
      value: filter.value,
    };

    if (filter?.nestedVariable?.field) {
      _filter.variable += `.${filter.nestedVariable.field}`;
    }

    if (filter?.variable?.not === true) _filter.not = true;
    if (filter?.variable?.exists === true) _filter.exists = true;

    return _filter;
  });
}

export async function aiFilterBuilder(
  userRequirements: string,
  funnelId: string
): Promise<{
  processedFilters: {
    filters: any[][];
    date: { format: string; range?: { start: string; end: string }; relative?: string };
  };
  requestId: string;
}> {
  const result = await onCallCrmGateway<"aiFilterBuilder">({
    functionName: "aiFilterBuilder",
    data: {
      userRequirements,
      funnelId,
    },
  });

  if (result?.data?.error || !result?.data?.data || !result.data.data?.data || result?.data?.exception) {
    return {
      processedFilters: {
        filters: [],
        date: { format: "none" },
      },
      requestId: "",
    };
  }

  return result.data.data.data;
}
