import { ClientDownloadFileMeta } from "@claimsgate/core-types";
/**Downloads a file to the client's browser */
export async function downloadFile(fileMeta: ClientDownloadFileMeta): Promise<void> {
  return new Promise((resolve) => {
    fetch(fileMeta.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = `${fileMeta.fileName}.${fileMeta.fileExtension}`;

        document.body.appendChild(a);

        a.click();
        window.URL.revokeObjectURL(url);
        resolve();
      });
  });
}

export const viewableFileTypes = [
  "application/xhtml+xml",
  "text/html",
  "image/apng",
  "application/wasm",
  "audio/mp3",
  "audio/wav",
  "audio/x-flac",
  "audio/x-m4a",
  "application/javascript",
  "video/x-m4v",
  "video/ogg",
  "application/epub+zip",
  "application/pdf",
  "video/mpeg",
  "audio/mpeg",
  "font/otf",
  "font/ttf",
  "font/woff",
  "font/woff2",
  "application/atom+xml",
  "application/ecmascript",
  "application/geo+json",
  "application/json",
  "application/jsonml+json",
  "application/ld+json",
  "application/manifest+json",
  "application/ogg",
  "application/rss+xml",
  "application/x-web-app-manifest+json",
  "application/xml",
  "audio/mp4",
  "audio/ogg",
  "audio/webm",
  "audio/x-wav",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/vnd.microsoft.icon",
  "image/webp",
  "image/x-icon",
  "image/x-xbitmap",
  "text/cache-manifest",
  "text/coffeescript",
  "text/css",
  "text/jade",
  "text/jsx",
  "text/less",
  "text/markdown",
  "text/mathml",
  "text/n3",
  "text/plain",
  "text/prs.lines.tag",
  "text/richtext",
  "text/sgml",
  "text/shex",
  "text/slim",
  "text/stylus",
  "text/troff",
  "text/turtle",
  "text/uri-list",
  "text/vnd.curl",
  "text/vnd.curl.dcurl",
  "text/vnd.curl.mcurl",
  "text/vnd.curl.scurl",
  "text/vnd.dvb.subtitle",
  "text/vnd.fly",
  "text/vnd.fmi.flexstor",
  "text/vnd.graphviz",
  "text/vnd.in3d.3dml",
  "text/vnd.in3d.spot",
  "text/vnd.wap.wml",
  "text/vnd.wap.wmlscript",
  "text/vtt",
  "text/x-asm",
  "text/x-c",
  "text/x-component",
  "text/x-fortran",
  "text/x-handlebars-template",
  "text/x-java-source",
  "text/x-lua",
  "text/x-markdown",
  "text/x-nfo",
  "text/x-opml",
  "text/x-org",
  "text/x-pascal",
  "text/x-processing",
  "text/x-sass",
  "text/x-scss",
  "text/x-setext",
  "text/x-sfv",
  "text/x-suse-ymp",
  "text/x-uuencode",
  "text/xml",
  "text/yaml",
  "video/mp4",
  "video/webm",
];
