/** Returns the userId of the user */
export function getUserId(): string | null {
  const authUser = localStorage.getItem("auth.currentUser");

  if (!authUser) return null;

  const user: { uid: string } = JSON.parse(authUser);

  return user.uid;
}
