import { FileMeta } from "@claimsgate/core-types";
import { generateFirestoreId } from "@claimsgate/core";
import { StorageService } from "@/helpers/ClaimsGate/StorageService";
import mime from "mime-types";
import { getFirebaseBackend } from "@/authUtils";

/**
 * Uploads files to the storage service in a temp location and returns the file metas
 * @returns - Array of file metas and null if there are no errors
 */
export async function uploadFiles({
  files,
  uploadingUserId,
  createMeta = false,
}: {
  files: Array<File>;
  uploadingUserId?: string;
  createMeta?: boolean;
}): Promise<[files: Array<FileMeta> | null, error: string | null]> {
  if (!files || files.length === 0) {
    return [[], "Please select a file to upload"];
  }

  if (files.some((file) => file.size > 30 * 1024 * 1024)) {
    return [null, "File size too big. Maximum 30 MB per file"];
  }

  const uploadData = [];

  files.forEach((file) => {
    uploadData.push({
      name: file.name,
      file,
      type: file.type,
      extension: file.name.split(".").pop(),
      fileSize: file.size,
    });
  });

  const userFilePath = `temp/${uploadingUserId}`;

  const storageService = new StorageService();

  const fileMetas = await Promise.all(
    uploadData.map(async (fileData) => {
      const fileId = generateFirestoreId();

      const filePath = `${userFilePath}/${fileId}`;

      const { data } = await storageService.uploadFile(filePath, fileData.file);

      if (!data) {
        throw new Error("Error uploading file");
      }

      const fileMeta: FileMeta = {
        name: fileData.name,
        type: fileData.type,
        extension: fileData.name.split(".").pop(),
        path: filePath,
        fileId,
        id: fileId,
        mime: mime.extension(fileData.type) || fileData.extension,
        createdAt: new Date(),
        fileSize: fileData.fileSize,
        uploadedBy: uploadingUserId,
      };

      return fileMeta;
    })
  );

  if (createMeta) {
    await createFileMetas(fileMetas, uploadingUserId);
  }

  return [fileMetas, null];
}

async function createFileMetas(fileMetas: Array<FileMeta>, userId: string) {
  await Promise.all(
    fileMetas.map((fileMeta) => {
      return getFirebaseBackend()
        .firestore()
        .collection("users")
        .doc(userId)
        .collection("files")
        .doc(fileMeta.id)
        .set(fileMeta);
    })
  );
}
